@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Bebas';
    src: url('../assets/fonts/Bebas_Neue/BebasNeue-Regular.ttf');
}

@font-face {
    font-family: 'Mate';
    src: url('../assets/fonts/Mate_SC/MateSC-Regular.ttf');
}

a::before{
    color: black;
}

a:hover {
    color: crimson;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.i-social {
    margin-right: 10px;
    font-size: 1.5rem;
}

.i_contact {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;    
}

.page-header {
    font-family: 'Mate';
    font-size: x-large;
}

// parent
.nav {
    width: 100vw;
    align-self: flex-start;
    top: 0;
    font-family: 'Bebas';
    font-size: x-large;
}

.brand{
    font-family: 'Bebas';
    font-size: xx-large;
}

.page {
    min-height: 100vh;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
}

.footer {
    align-self: center;
    margin-top: auto;
}

.btn-close {
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
      center/1em auto no-repeat;
  }

// page container
.p_generic_container {
    display: flex;
    gap: 50px;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 25px;
    background-color: whitesmoke;
}

// service page
.c_service_header {
    width: 100vw;
    text-align: center;
    font-size: xx-large;
    font-weight: 600;
    padding-top: 20px;
}

.c_service_div {
    width: 25%;

    &__heading {
        font-family: 'Mate';
        font-size: large;
        font-weight: 600;
        text-align: center;
        padding-bottom: 15px;
    }

    &__image {
        width: 35%;
        margin: auto;
        margin-bottom: 20px;
    }

    &__body {
        text-align: justify;
    }
}

// clients page
.c_clients_header {
    width: 100vw;
    text-align: center;
    font-size: xx-large;
    font-weight: 600;
    padding-top: 20px;
}

.c_clients_body {
    text-align: center;
    justify-content: center;
    width: 75%;

    &__title {
        font-size: small;
    }

    &__videoDiv {
        width: 32%;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
    }
}

// equipment page
.c_equipment_header {
    width: 100vw;
    text-align: center;
    font-size: xx-large;
    font-weight: 600;
    padding-top: 20px;
}

.c_equipment_header_div {
    width: 80%;
}

.c_equipment_div {
    width: 25%;
    box-shadow: 5px 5px 10px grey;
    border-radius: 5px;
    

    &__heading {
        padding-top: 15px;
        font-size: large;
        font-weight: 600;
        text-align: center;
        padding-bottom: 15px;
    }

    &__body {
        text-align: justify;

    }

    &__list_item {
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
        padding-bottom: 5px;
    }
}

.c_contact_div {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    width: 75%;
}

.c_form_div {
    justify-content: center;
    width: 50%;
}

// about page
.p_about_container {
    display: flex;
    justify-content: center;
    width: 100vw;
}

.c_about_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
}

// home page
.p_home_container {
    display: flex;
    justify-content: center;
    width: 100vw;
}

.c_home_div {
    justify-content: center;
    width: 100%;

    &__text {
        text-align: center;
        font-size: x-large;
        margin-top: 20px;
        font-family: 'Bebas';
    }
}

// photo carousel
.c_carousel_div {
    display: flex;
    flex-wrap: column wrap;
    height: 75vh;
    background-color: black;
    justify-content: center;
    align-items: center;

    &__img {
        max-height: 75vh;
    }

    &__spotifyPlayer {
        align-items: center;
    }
}

// listen page
.c_iframe_vimeo_vid {
    width: 95%;
    height:250px;
}

.c_iframe_youtube_vid {
    width: 514px;
    height:342px;
 }

.c_clients_iframe__video {
    width:560px; 
    height:315px;
}

// desktop
@media (min-width: 701px) {
    .spiel {
        width: 60%;
    }
}

// tablet
@media (max-width: 700px) {
    .c_service_div {
        width: 40%;
    }

    .c_equipment_div {
        width: 60%;
    }

    .c_clients_body__videoDiv {
        width: 65%;
    }
 }

 // phone
@media (max-width: 500px) {
    .c_service_div {
        width: 85%;
    }

    .c_equipment_div {
        width: 85%;
    }

    .c_clients_body__videoDiv {
        width: 85%;
    }

    .c_home_div__text {
            margin-top: 70px;
    }

    .c_carousel_div {
        height: 65vh;
    }

    .c_iframe_vimeo_vid {
        width: 80%;
        height: 50%;
    }

    .c_iframe_youtube_vid {
        width: 80vw;
    }
    
    .c_form_div {
        width: 100%;
    }

    .c_clients_iframe__video {
        width:80%; 
        height:100%;
    }
 }